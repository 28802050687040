import { TableDataProvider } from '@/components/Table/models/TableDataProvider';
import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchTlnImportLogDto } from '@/models/Dtos/searchTlnImportLogDto';
import { TlnImportLog } from '@/models/Entities/Imports/TlnImportLog';
import { TlnImportLogTableData } from './TlnImportLogTableData';

export class TlnImportLogDataProvider extends TableDataProvider<
  TlnImportLogTableData,
  TlnImportLog,
  SearchTlnImportLogDto
> {
  protected transformer = (t: TlnImportLog): TlnImportLogTableData =>
    new TlnImportLogTableData(t);

  protected queryHandler = async (
    searchDto: SearchTlnImportLogDto
  ): Promise<SearchResponseDto<TlnImportLog>> => {
    const results: SearchResponseDto<TlnImportLog> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'tln-import-log', query: searchDto, model: TlnImportLog }
    );
    return results;
  };
}
