
import IconComponent from '@/components/IconComponent.vue';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import TableFilterComponent from '@/components/Table/TableFilterComponent.vue';
import { TlnImportLogDataProvider } from '@/components/Table/TLN/TlnImportLog/TlnImportLogDataProvider';
import { tlnImportLogTableConfigBuilder } from '@/components/Table/TLN/TlnImportLog/TlnImportLogTableConfigBuilder';
import { TlnImportLogTableData } from '@/components/Table/TLN/TlnImportLog/TlnImportLogTableData';
import FetchMixin from '@/mixins/FetchMixin';
import { SearchTlnImportLogDto } from '@/models/Dtos/searchTlnImportLogDto';
import { TlnImportLog } from '@/models/Entities/Imports/TlnImportLog';
import Routes from '@/router/Routes';
import { formatDate } from '@/services/formatService';
import { Component, Mixins, Vue } from 'vue-property-decorator';

@Component<TlnImportPage>({
  components: {
    AsyncTableComponent,
    TableFilterComponent,
    QuickFilterComponent,
    LinkElementComponent,
    IconComponent
  }
})
export default class TlnImportPage extends Mixins(Vue, FetchMixin) {
  routes = Routes;
  latestDateTime = '...';
  searchService = new TableSearchService<
    TlnImportLogTableData,
    TlnImportLog,
    never,
    SearchTlnImportLogDto
  >(
    this.$store,
    new TlnImportLogDataProvider(this.$store),
    {},
    { defaultSortProperty: 'createDateTime', isAscendingSortOrder: false },
    tlnImportLogTableConfigBuilder,
    {
      filters: [],
      quickFilters: []
    },
    undefined
  );
  mounted(): void {
    this.queryLatestDate();
    this.searchService.queryData();
  }
  async queryLatestDate(): Promise<void> {
    const date = await this.getLatest('tln-import-log', TlnImportLog);
    if (date) {
      this.latestDateTime = formatDate(date);
    }
  }
}
