import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { TableConfigBuilder } from '@/components/Table/models/TableConfigBuilder';
import { SearchTlnImportLogDto } from '@/models/Dtos/searchTlnImportLogDto';
import { TlnImportLog } from '@/models/Entities/Imports/TlnImportLog';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import { TlnImportLogTableData } from './TlnImportLogTableData';
import TextWithColorElementComponent from '@/components/Table/Elements/TextWithColorElementComponent.vue';

export const tlnImportLogTableConfigBuilder: TableConfigBuilder<
  TlnImportLogTableData,
  TlnImportLog,
  never,
  SearchTlnImportLogDto
> = (): ITableConfiguration<TlnImportLogTableData, TlnImportLog, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Import Date',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowData: TlnImportLogTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.importDateString,
              linkTo: {
                name: Routes.TLN_IMPORT_REPORT_DETAILS,
                params: { tlnImportId: rowData.entityId }
              }
            };
          }
        },
        sortKey: 'createDateTime'
      },
      {
        columnTitle: 'Import Time',
        render: 'importTime',
        sortKey: 'createDateTime'
      },
      {
        columnTitle: 'Errors',
        render: {
          componentRef: TextWithColorElementComponent,
          componentProps: (
            rowData: TlnImportLogTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.totalErrors,
              color: !rowData.totalErrors ? 'black' : 'val-red'
            };
          }
        },
        sortKey: 'totalErrors'
      }
    ]
  };
};
