import { TlnImportLog } from '@/models/Entities/Imports/TlnImportLog';
import { formatDate, formatTime } from '@/services/formatService';

export class TlnImportLogTableData {
  entityId: number;
  importDate: Date;
  importDateString: string;
  importTime: string;
  importedBy: number | null | undefined;
  numberOfTLNsImported: number | null;
  dateShiftsInTLNs: number | null;
  movedToOutprocessing: number | null;
  TLNsDeleted: number | null;
  coursesCreated: number | null;
  totalErrors: number | null;

  constructor(tlnImportLog: TlnImportLog) {
    this.movedToOutprocessing = null;
    this.dateShiftsInTLNs = null;
    this.coursesCreated = null;
    this.totalErrors = tlnImportLog.totalErrors;
    this.numberOfTLNsImported = null;
    this.TLNsDeleted = null;
    this.entityId = tlnImportLog.id;
    this.importDate = tlnImportLog.createDateTime;
    this.importDateString = formatDate(this.importDate);
    this.importTime = formatTime(tlnImportLog.createDateTime);
    this.importedBy = tlnImportLog.createdBy;
  }
}
